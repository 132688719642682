<template>
    <v-container>
        Visitgradoli.com by Martijn Franke
    </v-container>
</template>

<script>

export default {
	name: 'Info',
	components: {
	},
	data () {
		return {
        }
    }
}

</script>